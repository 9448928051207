var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{ref:"formWizard",attrs:{"color":"#393939","error-color":"#ff4949","step-size":"xs","title":"","subtitle":""},on:{"on-complete":_vm.doSubmitFinish},scopedSlots:_vm._u([{key:"footer",fn:function(props){return [_c('div',{staticClass:"wizard-footer-left"},[(props.activeTabIndex > 0)?_c('wizard-button',{style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.prevTab()}}},[_c('app-i18n',{attrs:{"code":"common.previous"}})],1):_vm._e()],1),_c('div',{staticClass:"wizard-footer-right"},[_c('div',{staticClass:"wizard-btn"},[(
              !(
                (_vm.isCompletionRequested &&
                  !_vm.hasPermissionToCloseProject) ||
                _vm.isDisabled
              )
            )?_c('wizard-button',{staticClass:"wizard-footer-right",style:(props.fillButtonStyle),attrs:{"icon":"el-icon-fa-floppy-o"},nativeOn:{"click":function($event){return _vm.doSubmit.apply(null, arguments)}}},[_c('app-i18n',{attrs:{"code":"common.save"}})],1):_vm._e()],1),_c('div',{staticClass:"wizard-btn"},[(
              !_vm.isCompletionRequested && !(_vm.saveLoading || _vm.isDisabled)
            )?_c('wizard-button',{staticClass:"wizard-footer-right finish-button",style:(props.fillButtonStyle),nativeOn:{"click":function($event){return _vm.doSubmitEnd()}}},[_c('app-i18n',{attrs:{"code":"common.endProject"}})],1):_vm._e(),(
              _vm.isCompletionRequested &&
                _vm.hasPermissionToCloseProject &&
                !(
                  _vm.saveLoading ||
                  !_vm.isCompletionRequested ||
                  _vm.isDisabled
                )
            )?_c('wizard-button',{staticClass:"wizard-footer-right finish-button",style:(props.fillButtonStyle),nativeOn:{"click":function($event){return _vm.doSubmitFinish()}}},[_c('app-i18n',{attrs:{"code":"common.finishProject"}})],1):_vm._e()],1)])]}}])},[_c('tab-content',{attrs:{"icon":"ti-user"}},[(_vm.model)?_c('el-form',{ref:"form",staticClass:"form",attrs:{"label-position":_vm.labelPosition,"label-width":_vm.labelWidthForm,"model":_vm.model,"rules":_vm.rules,"disabled":_vm.isDisabled},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.doSubmit.apply(null, arguments)}}},[_c('app-template-view-file-page',{attrs:{"templateType":"projectReport"}}),_c('el-form-item',{attrs:{"label":_vm.fields.finalReportFile.label,"prop":_vm.fields.finalReportFile.name,"required":_vm.fields.finalReportFile.required}},[_c('el-col',{attrs:{"lg":13,"md":16,"sm":24}},[_c('app-file-upload',{attrs:{"max":_vm.fields.finalReportFile.max,"storage":_vm.fields.finalReportFile.storage,"formats":_vm.fields.finalReportFile.formats,"permissions":_vm.fields.finalReportFile.permissions},model:{value:(_vm.model[_vm.fields.finalReportFile.name]),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.finalReportFile.name, $$v)},expression:"model[fields.finalReportFile.name]"}})],1)],1),_c('el-form-item',{attrs:{"label":_vm.fields.finalProjectBudgetAndFunding.label,"prop":_vm.fields.finalProjectBudgetAndFunding.name,"required":_vm.fields.finalProjectBudgetAndFunding.required}},[_c('el-col',{attrs:{"lg":13,"md":16,"sm":24}},[_c('app-file-upload',{attrs:{"max":_vm.fields.finalProjectBudgetAndFunding.max,"storage":_vm.fields.finalProjectBudgetAndFunding.storage,"formats":_vm.fields.finalProjectBudgetAndFunding.formats,"permissions":_vm.fields.finalProjectBudgetAndFunding.permissions},model:{value:(
                _vm.model[_vm.fields.finalProjectBudgetAndFunding.name]
              ),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.finalProjectBudgetAndFunding.name, $$v)},expression:"\n                model[fields.finalProjectBudgetAndFunding.name]\n              "}})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }