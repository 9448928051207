<template>
  <div>
    <form-wizard
      @on-complete="doSubmitFinish"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      ref="formWizard"
      title=""
      subtitle=""
    >
      <tab-content icon="ti-user">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
          :disabled="isDisabled"
        >
          <!-- Template to download-->
          <app-template-view-file-page
            templateType="projectReport"
          ></app-template-view-file-page>

          <el-form-item
            :label="fields.finalReportFile.label"
            :prop="fields.finalReportFile.name"
            :required="fields.finalReportFile.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.finalReportFile.max"
                :storage="fields.finalReportFile.storage"
                :formats="fields.finalReportFile.formats"
                :permissions="fields.finalReportFile.permissions"
                v-model="model[fields.finalReportFile.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.finalProjectBudgetAndFunding.label"
            :prop="fields.finalProjectBudgetAndFunding.name"
            :required="fields.finalProjectBudgetAndFunding.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.finalProjectBudgetAndFunding.max"
                :storage="fields.finalProjectBudgetAndFunding.storage"
                :formats="fields.finalProjectBudgetAndFunding.formats"
                :permissions="
                  fields.finalProjectBudgetAndFunding.permissions
                "
                v-model="
                  model[fields.finalProjectBudgetAndFunding.name]
                "
              ></app-file-upload>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <!-- Wzard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            ><app-i18n code="common.previous"></app-i18n
          ></wizard-button>
        </div>
        <div class="wizard-footer-right">
          <div class="wizard-btn">
            <wizard-button
              icon="el-icon-fa-floppy-o"
              @click.native="doSubmit"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              v-if="
                !(
                  (isCompletionRequested &&
                    !hasPermissionToCloseProject) ||
                  isDisabled
                )
              "
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              @click.native="doSubmitEnd()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="
                !isCompletionRequested && !(saveLoading || isDisabled)
              "
              ><app-i18n code="common.endProject"></app-i18n
            ></wizard-button>
            <wizard-button
              @click.native="doSubmitFinish()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="
                isCompletionRequested &&
                  hasPermissionToCloseProject &&
                  !(
                    saveLoading ||
                    !isCompletionRequested ||
                    isDisabled
                  )
              "
              ><app-i18n code="common.finishProject"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectCompletionModel } from '@/modules/project-completion/project-completion-model';
import Templates from '@/modules/template/components/template-view-file-page';
import { ProjectCompletionPermissions } from '@/modules/project-completion/project-completion-permissions';

const { fields } = ProjectCompletionModel;
const formSchema = new FormSchema([
  fields.id,
  fields.finalReportFile,
  fields.finalProjectBudgetAndFunding,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-project-completion-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  components: {
    [Templates.name]: Templates,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      currentUser: 'auth/currentUser',
    }),

    isDisabled() {
      return this.model.status === 'projectCompleted';
    },

    fields() {
      return fields;
    },

    isCompletionRequested() {
      return this.model.status === 'projectCompletionRequested';
    },

    status() {
      return this.model.status;
    },

    hasPermissionToCloseProject() {
      return new ProjectCompletionPermissions(this.currentUser)
        .closeProject;
    },
  },

  watch: {
    record: function() {
      this.doUpdateModel();
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'projectCompletion/form/doSetFormModel',
          this.model,
        );
      },
    },
  },

  methods: {
    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      let { id, ...values } = formSchema.cast(this.model);
      return this.$emit('submit', {
        id,
        values,
      });
    },

    async setStatus(value) {
      this.model.status = value;
    },

    async doSubmitEnd() {
      this.setStatus('projectCompletionRequested');
      this.doSubmit();
    },

    async doSubmitFinish() {
      this.setStatus('projectCompleted');
      this.doSubmit();
    },
  },
};
</script>

<style></style>
