<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-completion-form
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      @submit="doSubmit"
      v-if="!findLoading"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectCompletionForm from '@/modules/project-completion/components/project-completion-form';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';

export default {
  name: 'app-project-completion-form-project-page',

  components: {
    [ProjectCompletionForm.name]: ProjectCompletionForm,
  },

  computed: {
    ...mapGetters({
      record: 'projectCompletion/form/record',
      findLoading: 'projectCompletion/form/findLoading',
      saveLoading: 'projectCompletion/form/saveLoading', 
      pendingFiles: 'projectCompletion/form/pendingFiles',
      status: 'projectCompletion/form/status',
      projectId: 'project/form/projectId',
      projectCompletionId: 'project/form/projectCompletionId',
    }),

    isEditing() {
      return !!this.projectCompletionId;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectCompletionId);
    } else {
      await this.doNew();
    }
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    if (this.status === 'projectCompleted' || to.name.includes("error")) {
      next();
      return;
    }

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'projectCompletion/form/doFind',
      doNew: 'projectCompletion/form/doNew',
      doUpdate: 'projectCompletion/form/doUpdate',
      doCreate: 'projectCompletion/form/doCreate',
      doSetProjectCompletionId:
        'project/form/doSetProjectCompletionId',
      doUpdateProject: 'project/form/doUpdate',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        payload.values.status = 'inProgress';
        const id = await this.doCreate(payload.values);
        this.doSetProjectCompletionId(id);
        this.doUpdateProject();
      }
    },
  },
};
</script>

<style>
</style>
