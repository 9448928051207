import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import FilesField from '@/shared/fields/files-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.projectCompletion.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  finalReportFile: new FilesField(
    'finalReportFile',
    label('finalReportFile'),
    Storage.values.projectCompletionFinalReportFile,
    Permissions.getFilePermissions(
      'projectCompletionFileFields',
      'finalReportFile',
    ),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  finalProjectBudgetAndFunding: new FilesField(
    'finalProjectBudgetAndFunding',
    label('finalProjectBudgetAndFunding'),
    Storage.values.projectCompletionFinalProjectBudgetAndFunding,
    Permissions.getFilePermissions(
      'projectCompletionFileFields',
      'finalProjectBudgetAndFunding',
    ),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'projectCompletionRequested',
        label: 'projectCompletionRequested',
      },
      { id: 'projectCompleted', label: 'projectCompleted' },
    ],
    { required: true },
  ),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectCompletionModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
