import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ProjectCompletionPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.projectCompletionRead,
    );
    this.create = permissionChecker.match(
      Permissions.values.projectCompletionCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.projectCompletionEdit,
    );
    this.closeProject = permissionChecker.match(
      Permissions.values.projectCompletionCloseProject,
    );  
    this.navigate = permissionChecker.match(
      Permissions.values.projectCompletionCloseNavigate,
    );  

  }
}
